<app-header></app-header>

<bit-container>
  <app-profile></app-profile>

  <app-danger-zone>
    <button type="button" bitButton buttonType="danger" (click)="deauthorizeSessions()">
      {{ "deauthorizeSessions" | i18n }}
    </button>
    <button type="button" bitButton buttonType="danger" [bitAction]="purgeVault">
      {{ "purgeVault" | i18n }}
    </button>
    <button type="button" bitButton buttonType="danger" [bitAction]="deleteAccount">
      {{ "deleteAccount" | i18n }}
    </button>
  </app-danger-zone>

  <ng-template #deauthorizeSessionsTemplate></ng-template>
  <ng-template #viewUserApiKeyTemplate></ng-template>
  <ng-template #rotateUserApiKeyTemplate></ng-template>
</bit-container>
